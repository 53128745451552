<template>
  <div class="box1">
<!--    <div class="fenge"></div>-->
    <div class="footer">
      <div class="footerBox">
        <div v-if="dates.copyright">{{dates.copyright}}</div>
        <div v-if="dates.address">地址：{{dates.address}}</div>
        <div v-if="dates.phone">电话：{{dates.phone}}</div>
        <div v-if="dates.mailbox">邮箱：{{dates.mailbox}}</div>
        <div v-if="dates.technicalSupport">技术支持：{{dates.technicalSupport}}</div>
        <div v-if="text" class="ql-editor" v-html="text"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectBottom,getTextarea } from "@/api/template";
export default {
  name: "TemplateFooter",
  components: {},
  data() {
    return {
      dates: {copyright:'',recordNumber:'',address:'',phone:'',mailbox:'',technicalSupport:''},
      text:''
    };
  },
  created(){
    this.init()
  },
  methods: {
    init(){
      selectBottom().then(res =>{
        if(res.data.length>0){
          this.dates = res.data[0]
          this.getTextareaFun(res.data[0].content)
        }
      }).catch(() =>{})
    },
    getTextareaFun(journalisContent){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        this.text = res.data.dataHtml
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.fenge{
  background-color: #333333;
  height: 30px;
  margin-top: 20px;
  clear: both;
}
.footer{
  background-color: #333333;
  padding: 20px 0;
  clear: both;
  .footerBox{
    width: 1200px;
    margin: 0 auto;
    color: #d2d2d2;
    font-size: 16px;
    line-height: 30px;
    span{
      margin-right: 30px;
    }
  }
}
</style>
